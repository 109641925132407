import React from "react"
import { DefaultLayout } from "layouts"
import { Home } from "containers"
import { Slides } from "components"

import articleThumbnail01 from "../images/article-thumbnail-01.png"
import articleThumbnail02 from "../images/article-thumbnail-02.png"
import articleThumbnail03 from "../images/article-thumbnail-03.png"
import testimonial01 from "../images/testimonial-01.png"
import testimonial02 from "../images/testimonial-02.png"
import nowCouriersLogo from "../images/now-couriers-nz-logo.png"
import theCoffeeGroupLogo from "../images/the-coffee-club.jpeg"
import logo from "../images/logo-white.svg"
import guy1final2 from "../images/guy1final2.png"
import bannerComputer from "../images/banner-computer.png"

const articlesProps: IArticles = {
  headline: "Our latest news",
  subHeadline: (
    <>
      Be the fist to discover the last from <b>B&F New Zealand</b>
    </>
  ),
  articles: [
    {
      thumbnail: articleThumbnail01,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail02,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
    },
    {
      thumbnail: articleThumbnail03,
      publishingDate: "September 01 2021",
      title: "I don’t care if a reader hates one of my stories",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form…",
      slug: "/",
      hasVideo: true,
    },
  ],
  actions: [
    {
      button: "View more",
      url: "/",
    },
  ],
}

const bannerWithPictureProps: IBannerWithPicture = {
  headline: "Time is money",
  description:
    "Make your orders faster with our new clients dashboard, all your transactions in one screen, faster, easier, smarter.",
  actions: [
    {
      button: "ACCESS YOUR ACCOUNT",
      url: "/",
    },
  ],
  picture: guy1final2,
}

const bannerWithLeftPictureProps: IBannerWithLeftPicture = {
  headline: "There’s a paper for that",
  description:
    "Access the biggest paper directory ever created, a huge collection of paper information, including uses, technical data, specifications and availability.",
  actions: [
    {
      button: "ACCES OUR CATALOG",
      url: "/",
    },
    {
      button: "SEARCH FOR A PAPER",
      url: "/",
    },
  ],
  picture: bannerComputer,
}

const testimonialsProps: ITestimonials = {
  testimonials: [
    {
      background: testimonial01,
      logo: logo,
      headline: "Real Open boxes Project",
      actions: [
        {
          button: "VIEW CASE",
          url: "/",
        },
      ],
      author: {
        avatar: nowCouriersLogo,
        name: "Sami Wade",
        company: "Now Couriers NZ",
      },
      text: `My project was a simple & small task, but the determination turned it into an awesome and great project. They help me accelerate my business.`,
    },
    {
      background: testimonial02,
      logo: logo,
      headline: "NZ Coffee Packaging Project",
      actions: [
        {
          button: "VIEW CASE",
          url: "/",
        },
      ],
      author: {
        avatar: theCoffeeGroupLogo,
        name: "Charles Zuckerberg",
        company: "New Zealand Coffee Club",
      },
      text: `My project was a simple & small task, but the determination turned it into an awesome and great project. They help me accelerate my business.`,
    },
  ],
}

const carouselProps: ICarousel = {
  sliders: [<Slides.FirstSlide />, <Slides.SecondSlide />],
}

const homeProps: IHomeContainerProps = {
  bannerWithPictureProps,
  bannerWithLeftPictureProps,
  carouselProps,
  articlesProps,
  testimonialsProps,
}

const Index = (props: any) => {
  return (
    <DefaultLayout {...props}>
      <Home {...homeProps} />
    </DefaultLayout>
  )
}

export default Index
